.hero-img {
    background-image: url('../assets/heroimg.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;;
    height: 35rem;
}
.hero-mask {
    background-color: rgba(19, 19, 19, 0.85);
}

.card {
    max-width: 50rem !important;
}