@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');


.App {
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}

.homeCover {
  background-image: url('../assets/HeroPic.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 75vh;
}

.hero {
  background-color: rgba(0, 0, 0, 0.65);
}

.logo-break {
  display: none;
}

.about-text {
  text-align: justify;
}
.list-item {
  list-style-type: decimal;
  list-style-position: inside;
}


.heading {
  font-size: 2rem;
}
.subHeading {
  width: clamp(50%, 70%, 80%);
  text-align: justify;
  font-weight: 500;
}
.list {
  text-align: start;
  margin: auto;
  list-style-type: square;
  font-weight: normal;
  width: clamp(50%, 80%, 90%);
}
.certificationImg {
  width: clamp(40%, 80%, 90%);
}
.bglightblack {
  background-color: #121212;
}
.bgblacksecondary {
  background-color: #0f0f0f;
}
.text-justify {
  text-align: justify;
}
.carousel-control-prev-icon, .carousel-control-next-icon {
  background-color: #000 !important;
  padding: 1rem !important;
}


@media (max-width: 480px) {
  .logo-break {
    display: unset;
  }
}